body {
  margin: 0 auto;
  overflow-x: hidden;
}

.App {
  /* text-align: center; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: poppins;
}

a {
  cursor: pointer;
}

/* navigation bar */

.nav-bar {
  display: flex;
  width: 97%;

  padding: 24px 24px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--slate-100, #eceef2);
  background: rgba(255, 255, 255, 0.8);
  position: sticky;

  top: 0;
  backdrop-filter: blur(16px);
  transition: top 0.3s;
  z-index: 10;
}

.nav-bar h1 {
  text-align: left;
  margin: 0;
  color: #3a4252;
}

.nav-bar-logo {
  text-decoration: none;
}

.rotate {
  transform: rotateY(360deg);
  transition: ease-in-out 2s;
}

.nav-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 48px;
}

.btn-lang {
  background-color: inherit;
  cursor: pointer;
  color: #3032a3;
  font-size: 18px;
  font-weight: 500;
  border: none;
}

.btn-lang:hover {
  text-decoration: underline;
}

.nav-box a {
  color: var(--slate-950, #23272e);

  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.nav-box a:hover {
  padding-bottom: 2px;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-position: 0 100%;
  background-size: 100% 2px;
  background-repeat: repeat-x;
  font-weight: 600;
  transition: 0.2s;
}

.nav-box-selected {
  color: var(--slate-950, #23272e);

  font-size: 1.5vw !important;
  font-style: normal;
  line-height: normal;
  text-decoration: none;
  padding-bottom: 2px;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-position: 0 100%;
  background-size: 100% 2px;
  background-repeat: repeat-x;
  font-weight: 700 !important;
  transition: 0.2s;
}

.get-in-touch-btn {
  color: #fff;

  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  padding: 16px;
  border-radius: 4px;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
}

/* hero section */

.hero-static-box {
  display: none;
}

.hero-section {
  display: flex;
  width: 90%;
  margin-top: 20px;
  padding: 50px 100px 24px 50px;
  align-items: center;
  gap: 5%;
}

.hero-box {
  display: flex;
  width: 50%;
  padding-left: 50px;
  flex-direction: column;
  align-items: flex-start;

  flex-shrink: 0;
}

.hero-title-box {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
}

.hero-title-box h2 {
  align-self: stretch;
  text-align: start;
  color: var(--slate-950, #23272e);
  white-space: wrap;
  font-size: 2.7vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.2;
  margin-bottom: 0;
  margin-top: 20px;
}

.hero-box p {
  text-align: start;
  align-self: stretch;
  color: var(--slate-500, #64748b);
  font-family: poppins;
  white-space: normal;
  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 50px;
  width: 100%;
}

.hero-box h3 {
  color: var(--slate-600, #526077);
  margin-top: 30px;
  font-size: 1.4vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.platform-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}

.hero-btn {
  color: #fff;
  font-family: poppins;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: -20px;
  box-shadow: 0px 5px 10px 0px rgba(70, 73, 229, 0.4);
}

.hero-img-box {
  /* margin-left: 41px; */
  position: relative;
  width: 100%;
  height: 600px;
}

.ellipse {
  position: absolute;
  z-index: 1;
  width: 85%;
  bottom: 0;
  right: 0;
  left: 0;
}

.hero {
  position: absolute;
  z-index: 2;
  bottom: 20%;
  right: 23%;
  left: 0;
  top: 0;
  width: 55%;
  margin: auto;
  clip-path: ellipse(100% 50% at 61% 50%);
}

.pixel-hero {
  position: absolute;
  z-index: 1;

  left: 20%;

  width: 60%;
}

.hero-bubble {
  position: absolute;
  z-index: 3;
  right: 70%;
  bottom: 60%;
  width: 38%;

  transition: all 1s;
}

.hero-bubble-active {
  transform: perspective(100px) translate3d(-8px, -8px, 2px) rotate(2deg);

  flex-shrink: 0;
  transition: all 1.5s;
}

.hero-heart {
  width: 30%;

  z-index: 3;
  position: absolute;
  left: 65%;
  top: 5%;
  transition: all 1s;
}

.hero-heart-active {
  transform: perspective(100px) translate3d(8px, 20px, 2px) rotate(5deg);
  transition: all 1.5s;
}

/* todo section */

.todo-img-static {
  display: none;
}

.todo-section {
  display: flex;
  margin-top: 70px;
  position: relative;
  width: 84%;
  padding: 56px;
  flex-direction: row;
  justify-content: space-between;

  gap: 40px;
  border-radius: 32px;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
}

.todo-img-container {
  height: 200px;
  width: 100%;
  position: relative;
}

.todo-img {
  position: absolute;
  top: -100px;
  right: 0;
  width: 75%;
  transition: all 2s;
}

.todo-img-active {
  transform: translateY(10%);
  transition: all 2s;
}

.todo-section h2 {
  color: #fff;
  width: 35%;
  display: contents;
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  font-family: poppins;
}

.todo-section p {
  color: var(--royal-blue-100, #e0e1ff);
  width: 100%;

  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.todo-btn {
  margin-top: 20px;
  border-radius: 4px;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  border: 2px solid #fff;
}

/* our service */

.service-section {
  margin-top: 9%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.service-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.service-text h1 {
  color: var(--slate-950, #23272e);
  text-align: center;
  display: contents;
  font-size: 3.2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
}

.service-text div {
  display: grid;
  grid-template-rows: auto auto;
}

.service-text p {
  color: var(--slate-950, #23272e);
  text-align: center;
  margin: 0;

  font-size: 2vw;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
}

.service-text div :last-child {
  background: linear-gradient(92deg, #4649e5 13.16%, #9747ff 73.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  font-size: 2.1vw;
  font-style: normal;
  font-weight: 600;
  align-self: stretch;
}

.service-btn {
  margin-top: 5%;
  margin-bottom: 30px;
  padding: 18px 24px 18px 24px;
  box-shadow: 0px 5px 10px 0px rgba(70, 73, 229, 0.4);
  font-size: 1.3vw;
}

.service-box-inner-text {}

.service-box-1-image-static {
  display: none;
}

.service-box-1 a {
  position: absolute;
  bottom: 20px;
}

.service-box-1 {
  margin-top: 32px;
  display: flex;
  width: 92%;
  padding: 4%;
  padding-bottom: 6%;
  flex-direction: row;
  align-items: flex-start;
  gap: 30px;
  height: 50%;
  position: relative;
  border-radius: 32px;
  background: var(--slate-50, #f6f7f9);
  margin-bottom: 50px;
  transition: all 1s;
}

.service-box-1:hover {
  border-radius: 32px;
  background: var(--Royal-Blue-50, #eef);
  transition: all 1s;
}

.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.1);
  }
}

.service-box-1-text {
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.service-box-1-text h1 {
  width: 70%;
  white-space: wrap;
  font-size: 2.5vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.3;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 0;
  margin-top: 0;
}

.service-box-1-text h2 {
  width: 80%;
  color: var(--slate-500, #64748b);
  display: block;
  font-size: 2.1vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-bottom: 20px;
}

.service-box-1-image-box {
  position: relative;
  width: 70%;
  height: auto;
  max-width: 520px;
  min-height: 300px;
}

.speaker {
  position: absolute;

  right: 5%;
  top: 5%;

  width: 70%;

  transition: all 2s;
}

.speaker-active {
  transform: perspective(100px) translateZ(4px) rotate(2deg);

  transition: ease 2s;
}

.bubble {
  position: absolute;
  width: 35%;
  transition: all 2s;
  left: 5%;
  bottom: 50%;
}

.bubble-active {
  transform: perspective(100px) translate3d(-3px, -2px, 0) rotate(2deg);

  transition: all 2s;
}

.heart {
  position: absolute;
  width: 27%;
  transition: all 2s;
  bottom: 5%;
  left: 5%;
}

.heart-active {
  transform: perspective(100px) translate3d(-2px, 3px, 0) rotate(-5deg);

  transition: all 2s;
}

.read-more-box {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}

.read-more-box a {
  text-decoration: none;
  color: var(--royal-blue-600, #4649e5);

  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.read-more-box a:hover {
  padding-bottom: 2px;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-position: 0 100%;
  background-size: 100% 2px;
  background-repeat: repeat-x;
  font-weight: 600;
  transition: 0.5s;
}

.rest-service-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.service-box-2-image-static {
  display: none;
}

.service-box-2 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 39.5%;
  padding: 4%;
  padding-top: 3%;

  align-items: flex-start;

  border-radius: 32px;
  background: var(--slate-50, #f6f7f9);
}

.service-box-2:hover {
  border-radius: 32px;
  background: var(--Royal-Blue-50, #eef);
  transition: all 1s;
}

.service-box-2 a {
  position: absolute;
  bottom: 20px;
}

.service-box-2 h1 {
  width: 70%;

  display: inline;

  font-size: 2.7vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.1;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-box-2 h2 {
  display: block;
  width: 100%;
  max-height: 96px;

  margin: 0;
  margin-top: 30px;
  margin-bottom: 30px;

  color: var(--slate-500, #64748b);
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.service-box-2-image-box {
  position: relative;
  width: 100%;
  height: 250px;
}

.bell {
  position: absolute;
  right: 0;
  width: 45%;
  bottom: 0;
  transition: ease 2s;
}

.bell-active {
  transform: perspective(100px) translateZ(10px) rotate(5deg);

  transition: ease 2s;
}

.service-box-3-image-static {
  display: none;
}

.service-box-3 a,
.service-box-4 a {
  position: absolute;
  bottom: 20px;
}

.service-box-3,
.service-box-4 {
  display: flex;
  flex-direction: column;
  width: 39.5%;
  padding: 4%;
  padding-top: 3%;
  position: relative;
  align-items: flex-start;

  border-radius: 32px;
  background: var(--slate-50, #f6f7f9);
}

.service-box-3:hover,
.service-box-4:hover {
  border-radius: 32px;
  background: var(--Royal-Blue-50, #eef);
  transition: all 1s;
}

.service-box-3 h1,
.service-box-4 h1 {
  width: 100%;
  font-size: 2.7vw;
  white-space: normal;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.service-box-3 h2,
.service-box-4 h2 {
  display: block;
  width: 100%;
  max-height: 96px;

  margin: 0;
  margin-top: 0;
  margin-bottom: 30px;

  color: var(--slate-500, #64748b);
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}

.service-box-3-image-box {
  position: relative;
  height: 250px;
  width: 100%;
}

.starship {
  width: 58%;
  left: 0;
  position: absolute;
  bottom: -20%;
  transition: ease 2s;
}

.starship-active {
  transform: perspective(100px) translate3d(20px, -20px, 5px);
}

.service-box-4 {
  flex-direction: row;
  justify-content: space-between;
  min-height: 250px;
}

.target {
  width: 60%;
}

/* form */

.form-box {
  display: flex;
  padding: 1%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  /* height: 550px; */
  gap: 3%;

  border-radius: 16px;
  background: #fff;
}

.form {
  width: 95%;
  min-width: 350px;
  padding-top: 5%;
}

.span-1 {
  color: red;
}

.span-2 {
  color: var(--Slate-300, #b1bbc8);
  font-family: Inter Display;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

form h4 {
  color: var(--slate-950, #23272e);
  margin-bottom: 0;
  margin-top: 0;
  font-size: 1.2vw;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  line-height: normal;
  padding-bottom: 2%;
  margin-top: 2%;
}

.cell-unit {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.personal-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5%;
  row-gap: 20%;
  padding-bottom: 10%;
}

.personal-details input {
  font-size: 1.2vw;
  margin: 0;
  padding: 5%;
  height: 100%;
  border-radius: 4px;
  border: 2px solid var(--slate-100, #eceef2);
}

input::placeholder {
  color: var(--slate-300, #b1bbc8);

  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

input:hover,
input:focus {
  border-radius: 4px;
  border: 2px solid var(--royal-blue-400, #8184f8);
}

.message {
  display: flex;
  width: 100%;
  min-height: 197px;
  flex-direction: column;
}

.message .cell-unit {
  width: 100%;
  height: 197px;
}

.message input {
  padding: 10px;
  height: 100%;

  font-size: 1.2vw;
  gap: 8px;

  border-radius: 4px;
  border: 2px solid var(--slate-100, #eceef2);
}

.message input::placeholder {
  color: var(--slate-300, #b1bbc8);
  margin-top: 0;

  font-size: 1.2vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.message input:hover,
.message input:focus {
  border-radius: 4px;
  border: 2px solid var(--royal-blue-400, #8184f8);
}

.form-btn-box {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  width: 95%;
}

.form-btn {
  display: flex;
  width: 50%;
  height: 56px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  box-shadow: 0px 5px 10px 0px rgba(70, 73, 229, 0.4);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 15px;
  height: 15px;
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid transparent;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* get in touch */
.get-in-touch {
  margin-top: 120px;
  display: flex;
  width: 82%;
  height: 600px;
  padding: 4%;
  align-items: center;
  justify-content: space-between;
  border-radius: 32px;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
}

.get-in-touch-title {
  max-width: 50%;
}

.get-in-touch h1 {
  color: #fff;

  font-size: 3vw;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  max-width: 80%;
}

.get-in-touch p {
  color: var(--royal-blue-200, #c7c8fe);

  font-size: 1.5vw;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

/* footer */

.footer {
  margin-top: 4%;
  margin-bottom: 4%;
  width: 90%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-image-box {
  width: 30%;
}

.footer-image-box img {
  min-width: 250px;
  width: 100%;
}

.footer-p-box {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
}

.footer p {
  color: var(--slate-400, #8695aa);
  font-style: normal;
  line-height: 21px;
  text-transform: uppercase;
  text-align: center;
  font-size: 1vw;
}

.footer-platform-box {
  width: 30%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.footer-platform-box img {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0;
}

/* button */

.get-in-touch-btn {
  cursor: pointer;
}

.get-in-touch-btn:hover {
  /* transition: ease-in-out 0.5s; */
  border-radius: 4px;
  background: #4649e5;
  box-shadow: 0px 3px 7px 0px rgba(70, 73, 229, 0.4);
}

.get-in-touch-btn:active {
  border-radius: 4px;
  background: var(--royal-blue-800, #3032a3);
  /* transition: ease-in-out 0.5s; */
}

.todo-btn {
  background: var(--Royal-Blue-600, #4649e5);
}

.todo-btn:hover {
  color: var(--royal-blue-600, #4649e5);
  transition: ease-in-out 0.3s;
  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: #fff;
}

.todo-btn:active {
  color: var(--royal-blue-600, #4649e5);
  transition: ease 0.3s;

  font-size: 1.3vw;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 4px;
  background: var(--royal-blue-200, #c7c8fe);
}

/* slideShow */

.hero-slide-show {
  /* margin-top: 10px; */
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-start;
}

.we-img {
  font-size: 50px;
  font-family: inherit;
  margin: 0;
  font-weight: 700;
}

.slideshow {
  width: 200px;
  height: 60px;
  overflow: hidden;
}

.slideshowSlider {
  display: flex;
  flex-direction: column;
  gap: 10px;
  transition: ease-in-out 300ms;
  margin-right: 12px;
  margin-left: 12px;
}

.slide {
  padding: 0;
  margin: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slide h1 {
  background: linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: 0;
  padding: 0;
  font-size: 50px;
}

/* .slide:first-child {
  animation: wobble 2s ease-in-out infinite;
} */

/* Our Services */

.all-services-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 4%;
  flex-wrap: wrap;
}

.service-section-cont {
  width: 28%;
  height: 100%;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 16px;
  margin-top: 24px;
}

.service-title {
  font-weight: 700;
  font-size: 32px;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 80%;
}

.service-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  /* text-align: left; */
  width: 90%;
  color: #526077;
}

.our-services-title-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.our-services-title-styles {
  font-weight: 900;
  font-size: 56px;
  text-align: center;
  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.our-services-description-styles {
  font-family: Inter Display;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  text-align: center;
  width: 50%;
}

.services-section-inner-title {
  font-family: Inter Display;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  color: #526077;
}

.section-service-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.check-mark-styles {
  width: 32px;
  height: 32px;
  margin-right: 8px;
  margin-left: 8px;
}

.service-image-description {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #526077;
}

.social-image {
  filter: grayscale(100%);
  transition: all 0.3s;
  margin-right: 8px !important;
}

.social-image:hover {
  filter: none;
}

.contact-us-section {
  display: flex;
  align-items: center;
}

.email-section {
  color: #000 !important;
  font-weight: bold !important;
  margin: 0px;
  font-size: 1vw;
}

.target-img-box {
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (max-width: 1300px) {
  .hero {
    top: 20%;
  }

  .hero-bubble {
    top: 5%;
  }

  .hero-heart {
    top: 15%;
  }
}

@media only screen and (max-width: 1250px) {
  .hero {
    width: 60%;
    top: 30%;
  }

  .hero-bubble {
    top: 10%;
  }

  .hero-heart {
    top: 25%;
  }
}

@media only screen and (max-width: 1000px) {
  .hero {
    top: 35%;
  }

  .hero-bubble {
    top: 20%;
  }

  .hero-heart {
    top: 30%;
  }

  .pixel-hero {
    top: 30%;
  }

  .nav-btn {
    display: none;
  }
}

@media only screen and (max-width: 800px) {
  .target {
    width: 38%;
  }

  .service-section-cont {
    width: 92%;
    margin-top: 24px;
    padding: 16px;
  }

  /* nav-bar */
  .nav-bar-logo {
    font-size: 2.2vw;
    display: inline-block;
    width: 60%;
    text-align: center;
    text-decoration: none;
  }

  .footer-p-box {
    display: flex;
    width: unset;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .email-section {
    font-size: 2.5vw !important;
  }

  .nav-bar-pages {
    font-size: 3.5vw !important;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    margin: 8px;
  }

  .nav-box {
    width: 60%;
    gap: 2%;
  }

  .nav-btn {
    width: 100%;
    font-size: 2vw;
    font-weight: 600;
  }

  .nav-bar h1 {
    text-align: left;
    margin: 0;
    color: #3a4252;
  }

  .logo-nav {
    width: 30%;
  }

  /* hero-section */

  .hero-section {
    flex-direction: column-reverse;
    padding: 0;
  }

  .pixel-hero {
    bottom: 1%;
  }

  .hero-box {
    display: grid;
    width: 100%;
    grid-template-rows: 40% 1fr 1fr 1fr 1fr;
    gap: 5%;
    margin-top: 10%;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .hero-title-box h2 {
    margin-top: 5px;
    font-size: 4vw;
    text-align: center;
    /* display: none; */
  }

  .hero-box p {
    font-size: 3.5vw;
    text-align: start;
    margin-top: 0;
  }

  .hero-btn {
    width: 60%;
    justify-self: center;
    font-size: 3vw;
  }

  .hero-box h3 {
    display: none;
  }

  .platform-box {
    display: none;
  }

  .platform-box img {
    height: 60%;
    width: auto;
  }

  .hero-slide-show {
    grid-row-start: span 2;
    justify-content: center;
    height: 40px;
    margin-bottom: 20px;
  }

  .hero-box * {
    margin: 0;
  }

  .hero-text {
    margin-top: 20px;
    display: none;
  }

  .hero {
    bottom: 15%;
  }

  /* todo */

  .todo-section {
    margin-top: 3%;
  }

  .todo-section h2 {
    font-size: 3.5vw;
  }

  .todo-section p {
    font-size: 2vw;
  }

  .todo-btn {
    font-size: 2vw;
  }

  /* service section */
  .service-section {
    margin-top: 15%;
  }

  .service-text h1 {
    font-size: 5.5vw;
  }

  .service-section p {
    font-size: 3vw;
  }

  .service-section p:last-child {
    font-size: 3vw;
  }

  .service-btn {
    font-size: 2.5vw;
  }

  .service-box-1-image-box {
    min-height: 150px;
  }

  .service-box-1 h1 {
    font-size: 4vw;
    display: inline;
    width: 100%;
  }

  .speaker {
    width: 50%;
    top: 20%;
  }

  .bubble {
    width: 30%;
    left: 30%;
  }

  .heart {
    width: 25%;
    left: 25%;
  }

  .read-more-box a {
    font-size: 2vw;
  }

  .read-more-box a:hover {
    text-decoration: underline 1px solid red;
  }

  .service-box-2,
  .service-box-3,
  .service-box-4 {
    gap: 0;
    flex-basis: 100%;
    min-height: 200px;
  }

  .rest-service-box {
    min-height: 150px;
  }

  .rest-service-box h1 {
    font-size: 4vw;
    margin: 0;
  }

  .rest-service-box h2 {
    display: inline-block;
    min-height: 40px;
  }

  .bell {
    width: 30%;
    bottom: 20%;
  }

  .starship {
    width: 38%;
    bottom: 5%;
    left: 25%;
  }

  .service-box-2-image-box,
  .service-box-3-image-box,
  .service-box-4-image-box {
    min-height: 100px;
    position: unset;
  }

  /* get-in-touch + form */
  .get-in-touch {
    flex-direction: column;
    height: unset;
  }

  .get-in-touch p {
    margin-bottom: 0;
  }

  .form-box {
    width: 100%;
    margin-top: 0;
  }

  .personal-details {
    display: flex;
    flex-direction: column;
  }

  .personal-details input {
    width: 88%;
    height: 5px;
    padding: 15px;
  }

  .message input {
    height: 50%;
    margin-bottom: 0;
  }

  .form-btn-box {
    margin-top: 5px;
    justify-content: center;
  }

  .form button {
    width: 50%;
    margin-top: 0;
    height: auto;
  }

  .form * {
    font-size: 1.5vw;
  }

  .message {
    height: 120px;
  }

  .message .cell-unit {
    height: 120px;
  }

  .get-in-touch-title {
    max-width: none;
  }

  .get-in-touch h1 {
    font-size: 4.5vw;
    white-space: nowrap;
    margin: 0;
    text-align: center;
  }

  .get-in-touch p {
    font-size: 2.5vw;
    margin: 0;
    text-align: center;
  }

  /* footer */
  .footer {
    margin-top: 2%;
    gap: 10%;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-image-box {
    width: unset;
    margin-top: 20px;
  }

  .footer-platform-box {
    width: unset;
    padding-bottom: 20px;
  }

  .footer p {
    font-size: 2.5vw;
    text-align: center;
  }
}

/* ------------------------------------------------------------- */

/* About Us Page */

/* title Box */
.about-us-title-box {
  background-color: var(--Slate-50, #f6f7f9);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16%;
  padding-top: 24px;
  padding-bottom: 24px;
}

.about-us-h1 {
  width: 95%;
  white-space: wrap;
  text-align: center;
}

.about-us-h1 h1 {
  margin: 0;
  font-family: sans-serif;
  font-size: 6.5vw;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  background: var(--Gradient-1,
      linear-gradient(96deg, #4649e5 5.93%, #9747ff 107.53%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-us-text-box p {
  color: var(--Slate-950, #23272e);
  font-family: sans-serif;
  font-size: 2.2vw;
  font-style: normal;
  font-weight: 400;
}

.our-team {
  padding-top: 5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.our-team h1 {
  text-align: center;
  font-size: 3vw;
}

.team-box {
  width: 85%;
  padding: 5%;
  padding-top: 0;
  align-items: center;
}

.unit-cell {
  width: 28%;
}

.member-image-box {
  display: flex;
  justify-content: center;
  gap: 5%;
}

.member-img {
  width: 100%;
}

.member-info {
  margin-top: 0;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.member-text {
  display: flex;
  flex-direction: column;
}

.member-text h2 {
  font-size: 2vw;
  margin-bottom: 0;
}

.member-text p {
  font-size: 1.2vw;
  color: var(--Slate-500, #64748b);
  font-family: sans-serif;

  margin-top: 1%;
}

@media only screen and (max-width: 450px) {
  .hero-section {
    flex-direction: column;
    padding: 0;
    grid-template-columns: 1fr 1fr;
  }

  /* .hero-slide-show {
    gap: 10px;
  } */

  .hero-box {
    padding: 0;
  }

  .hero-img-box {
    display: none;
  }

  .hero-static-box {
    display: block;

    height: 300px;
    position: relative;
  }

  .hero-static {
    width: 300px;
  }

  .hero-slide-show {
    padding-left: 50px;
    padding: 0;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0;
  }

  .we-img {
    font-size: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .slideshow {
    height: 50px;
    width: 200px;
  }

  .slideshowSlider {
    width: 70%;
    gap: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .slide {
    display: flex;
    justify-content: flex-start;
    height: 50px;
    width: 100%;
    align-content: center;

    padding: 0;
  }

  .slide h1 {
    font-size: 48px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }

  .todo-section {
    margin-top: 10%;
    padding: 5%;
  }

  .todo-text p {
    font-size: 2.5vw;
  }

  .todo-btn {
    font-size: 2.5vw;
  }

  .service-btn {
    font-size: 3vw;
  }

  .service-box-1 {
    padding: 8%;
  }

  .service-box-1-image-box {
    display: none;
  }

  .service-box-1-image-static {
    display: flex;
    justify-self: flex-end;
    align-items: center;
    width: 185px;
  }

  .speaker-static {
    width: 100%;
  }

  .rest-service-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 92%;
    flex-wrap: nowrap;
    gap: 5%;
  }

  .service-box-2-image-box {
    display: none;
  }

  .service-box-2-image-static {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .service-box-2 {
    width: 100%;
    margin-bottom: 15%;
    padding: 8%;
    flex-direction: row;
  }

  .service-box-2 h1 {
    width: 100%;
    margin-bottom: 5%;
  }

  .service-box-title-div {
    display: flex;
    width: 100%;
    flex-direction: column;
  }

  .bell-static {
    width: 50%;
  }

  .service-box-2 h2 {
    width: 100%;
    margin-bottom: 10%;
  }

  .service-box-3-image-box {
    display: none;
  }

  .service-box-3-image-static {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 95%;
  }

  .service-box-3 {
    width: 100%;
    height: 167px;
    padding: 8%;
    flex-direction: row;
    margin-top: 20px;
  }

  .starship-static {
    width: 80%;
  }

  .service-box-3 h1 {
    width: 100%;
    margin-bottom: 5%;
  }

  .service-box-3 h2 {
    width: 100%;
    margin-bottom: 0;
  }

  .service-box-4 {
    margin-top: 50px;
    width: 100%;
    padding: 8%;
  }

  .target {
    width: 70%;
  }

  .target-img-box {
    display: flex;
    justify-content: flex-end;
  }

  .form {
    min-width: unset;
    padding: 0;
  }

  .cell-unit {
    margin-top: 8%;
  }

  .footer {
    justify-content: space-between;
    align-items: center;
    gap: unset;
  }

  .footer-image-box {
    font-size: 2.8vw;
  }

  .footer-image-box img {
    min-width: unset;
    margin-top: 6%;
    width: 100%;
  }

  .footer-p-box {
    font-size: 2.8vw;
  }

  .footer p {
    font-size: 2.8vw;
  }

  .footer-platform-box {
    width: 100%;
    justify-content: space-around;
  }

  .cell-name {
    font-size: 2.4vw;
  }

  .span-1 {
    font-size: 2.5vw;
  }

  .span-2 {
    font-size: 2.5vw;
  }

  .personal-details input {
    padding-left: 3%;
  }

  input::placeholder {
    font-size: 2.5vw;
  }

  .get-in-touch-title {
    margin-bottom: 5%;
  }

  .get-in-touch-title p {
    margin-top: 5%;
  }

  .form-btn-box {
    margin-top: 5%;
  }

  .service-box-1 h2 {
    font-size: 3vw;
  }

  .service-box-title-div h2 {
    font-size: 3vw;
  }

  .service-box-3 .read-more-box {
    margin-top: 15%;
  }

  .read-more-box a {
    font-size: 2.5vw;
  }

  .message input::placeholder {
    font-size: 2.5vw;
  }

  .form-btn {
    font-size: 2.5vw;
  }

  .nav-box {
    width: 100%;
  }

  .nav-btn {
    display: none;
  }

  .nav-bar a {
    font-size: 2.5vw;
  }

  .nav-bar {
    padding: 24px 0px;
  }

  .App {
    padding-bottom: 150px;
  }

  .all-services-cont {
    flex-direction: column;
    align-items: center;
  }

  .service-title {
    width: unset;
    text-align: center;
  }
}

/* ----------------------- */
.single-service {
  display: flex;
  justify-content: space-between;
  align-items: center;
}